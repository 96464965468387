import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StoresProps } from "../../props/StoresProps"

const Wrapper = tw.div`flex flex-col w-full max-w-66`

const Store = styled.div`
  ${tw`py-4`}
  ${({ last }) => (last ? tw`border-0` : tw`border-b border-beige`)}
`

const Title = tw.h3`text-28 leading-1.29 md:text-32 md:leading-1.31 mb-1-6 md:mb-2-4 font-display`

const Section = styled.div`
  ${tw`flex flex-col md:flex-row justify-between`}
  ${({ last }) => (last ? tw`mb-0` : tw`md:mb-4`)}
`

const Address = tw.div`flex flex-row md:flex-col justify-between mb-2-4 md:mb-0`

const OpeningHours = styled.div`
  ${({ mobileHidden }) => (mobileHidden ? tw`hidden md:block` : null)}
`

const Text = styled.p`
  ${tw`text-16 leading-1.75`}
  ${({ bold }) => (bold ? tw`font-medium text-18` : null)}
  ${({ inline }) => (inline ? tw`inline` : null)}
`

const Phone = tw.a`text-18 leading-1.56 inline`

const Direction = tw.a`text-18 leading-1.56 underline`

const StoresDetail = ({ stores }) => {
  return (
    <Wrapper>
      {stores.map((store, index) => (
        <Store key={index} last={index === stores.length - 1}>
          <Title>{store.name}</Title>
          <div>
            {store.sections.map((section, index) => (
              <Section key={index} last={index === store.sections.length - 1}>
                <Address>
                  <div>
                    <Text bold>{section.title}</Text>
                    <Text>{section.address}</Text>
                    <Text>{`${section.city}, ${section.state} ${section.postcode}`}</Text>
                  </div>
                  <Direction
                    href={`https://maps.google.com/maps?q=${section.address} ${section.city} ${section.state} ${section.postcode}`}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  >
                    Get direction &gt;
                  </Direction>
                </Address>
                <OpeningHours mobileHidden={store.sections.length > 1 && index === 0}>
                  <Text bold>Opening hours</Text>
                  {section.openHours.map((openingHour, index) => (
                    <div key={index}>
                      <Text inline>{`${openingHour.day}: `}</Text>
                      <Text inline>{openingHour.hours}</Text>
                    </div>
                  ))}
                  <Phone href={`tel:${section.phone}`}>
                    <Text bold inline>
                      P.&nbsp;
                    </Text>
                    {section.phone}
                  </Phone>
                </OpeningHours>
              </Section>
            ))}
          </div>
        </Store>
      ))}
    </Wrapper>
  )
}

StoresDetail.propTypes = {
  stores: StoresProps,
}

export default StoresDetail
