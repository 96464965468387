import PropTypes from "prop-types"

export const StoresProps = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        openHours: PropTypes.arrayOf(
          PropTypes.shape({
            day: PropTypes.string,
            hour: PropTypes.string,
          })
        ),
        phone: PropTypes.string,
      })
    ),
  })
)

export const MapStoreProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
}

export const MapStoresProps = PropTypes.arrayOf(PropTypes.shape(MapStoreProps))
