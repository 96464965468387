import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withContact } from "./withContact"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledButton } from "../Styled/Button"
import ContactForm from "./ContactForm"
import ModalsPopup from "../Modals/ModalsPopup"
import PageTitle from "../PageTitle/PageTitle"
import StoresWithImage from "../Stores/StoresWithImage"

const Container = styled(StyledContainer)`
  ${tw`flex flex-wrap pt-1-6 md:pt-8`}
`

const Details = tw.div`
  w-full md:w-1/2
`

const Detail = tw.div`
  mb-3-2 md:mb-5-6 flex flex-col
`

const Title = styled.h2`
  ${tw`text-28 leading-1.29 md:text-32 md:leading-1.31 font-display`}
  ${({ inline }) => (inline ? tw`md:inline mr-0-4` : null)}
`

const Phone = tw.a`
  text-22 md:text-26 leading-1.62
`

const Button = styled(StyledButton)`
  ${tw`max-w-15 md:max-w-19 w-full mt-0-8`}
`

const Text = styled.p`
  ${tw`text-16 leading-1.75 md:leading-2`}
  ${({ desktop }) => (desktop ? tw`hidden md:inline` : null)}
  ${({ mobile }) => (mobile ? tw`inline md:hidden` : null)}
`

const ContactButton = tw.button`
  md:hidden focus:outline-none text-16 leading-1.75 inline underline
`

const Email = tw.a`
  text-16 leading-1.75 md:leading-2 inline underline
`

const Form = tw.div`
  w-full md:w-1/2 pb-4 md:pb-10-4
`

const DesktopForm = styled(ContactForm)`
  ${tw`hidden md:flex md:-mx-1-6 mt-4-8`}
`

const MobileForm = styled(ContactForm)`
  ${tw`md:hidden px-0-8 py-4`}
`

export const Contact = withContact(({ title, phone, contactHours, email, image, stores, paths, mobileFormActive, setMobileFormActive }) => (
  <>
    <PageTitle title={title} paths={paths} />
    <BackgroundContainer background={`light`}>
      <Container>
        <Details>
          <Detail>
            <Title>Call us</Title>
            <Phone href={`tel:${phone}`}>{phone}</Phone>
          </Detail>
          <Detail>
            <Title>Chat with us live</Title>
            <Button height={`56`} textSize={16} border>
              Chat now
            </Button>
          </Detail>
          <Detail>
            <Title>Contact hours</Title>
            {contactHours.map((item, index) => (
              <Text key={index}>
                {item.day}
                {item.hour}
              </Text>
            ))}
          </Detail>
        </Details>
        <Form>
          <div>
            <Title inline>Email us&nbsp;</Title>
            <div className={`inline`}>
              <Text desktop>(Email us directly at </Text>
              <Text mobile>Email us directly at </Text>
              <Email href={`mailto:${email}`} target={`_blank`} rel={`noopener noreferrer`}>
                {email}
              </Email>
              <Text desktop> or use </Text>
              <Text mobile> or use </Text>
              <Text desktop>the form below)</Text>
              <ContactButton onClick={() => setMobileFormActive(true)}>contact form</ContactButton>
            </div>
          </div>
          <DesktopForm />
        </Form>
        <ModalsPopup active={mobileFormActive} setActive={setMobileFormActive}>
          <MobileForm />
        </ModalsPopup>
      </Container>
    </BackgroundContainer>
    <StoresWithImage image={image} stores={stores} />
  </>
))

Contact.propTypes = {
  phone: PropTypes.string,
  contactHours: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
}

export default Contact
