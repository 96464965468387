import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withContactForm } from "./withContactForm"
import { StyledButton } from "../Styled/Button"
import FormInput from "../Form/FormInput"
import FormTextarea from "../Form/FormTextarea"

const Wrapper = tw.form`flex flex-wrap w-full`

const Title = tw.h2`
  md:hidden text-18 leading-2.25 mb-3-2 px-1-6 font-medium
`

const Input = styled(FormInput)`
  ${tw`mb-2-4`}
`

const Textarea = styled(FormTextarea)`
  ${tw`mb-2-4`}
`

const ButtonWrapper = tw.div`
  px-1-6 w-full
`

const Submit = styled(StyledButton)`
  ${tw`md:max-w-32 w-full`}
`

const Errors = tw.ul`
  px-1-6
`

const Error = tw.p`
  text-12 mb-1-2 text-orange
`

const ContactForm = withContactForm(({ className, data, handleChange, handleSubmit, loading, errors }) => {
  return (
    <Wrapper className={className} onSubmit={handleSubmit}>
      <Title>Contact form</Title>
      <Input
        name={`firstName`}
        width={`1/2`}
        placeholder={`First name`}
        value={data.firstName}
        handleChange={handleChange}
        required
        placeholderColor={`dark`}
      />
      <Input
        name={`lastName`}
        width={`1/2`}
        placeholder={`Last name`}
        value={data.lastName}
        handleChange={handleChange}
        required
        placeholderColor={`dark`}
      />
      <Input type={`email`} name={`email`} placeholder={`Email`} value={data.email} handleChange={handleChange} required placeholderColor={`dark`} />
      <Input name={`phone`} placeholder={`Phone`} value={data.phone} handleChange={handleChange} placeholderColor={`dark`} />
      <Textarea name={`message`} placeholder={`Your message`} value={data.message} handleChange={handleChange} placeholderColor={`dark`} showLength />
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <li key={index}>
              <Error>{error}</Error>
            </li>
          ))}
        </Errors>
      )}
      <ButtonWrapper>
        <Submit type={`submit`} height={`56`} textSize={`16`} colour={`green`} disabled={loading}>
          Send
        </Submit>
      </ButtonWrapper>
    </Wrapper>
  )
})

ContactForm.propTypes = {
  className: PropTypes.string,
}

export default ContactForm
