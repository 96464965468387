import React, { useEffect } from "react"

import { useForm } from "../../hooks/useForm"

export const withContactForm = Component => ({ name = "ContactForm", ...props }) => {
  const { getForm, submitForm, data, setData, loading, errors } = useForm()
  useEffect(() => {
    if (!data.type) {
      const type = data?.type ? form?.types[data?.type?.toLowerCase()]?.title : Object?.values(form?.types)[0]?.title
      setData(prevState => ({
        ...prevState,
        type,
      }))
    }
  })
  const form = getForm("contact")

  const handleSubmit = async event => {
    event.preventDefault()
    await submitForm(form, data)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return <Component data={data} form={form} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} errors={errors} {...props} />
}
