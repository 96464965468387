import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import ImageContent from "../ImageContent"
import StoresDetail from "./StoresDetail"
import { GatsbyImageProps } from "../../props/ImageProps"
import { StoresProps } from "../../props/StoresProps"

const Container = styled(StyledContainer)`
  ${tw`max-w-xl mx-auto`}
`

const Content = tw.div`flex items-center justify-center w-full h-full px-2-4 bg-lightest`

const StoresWithImage = ({ image, stores }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container width={`full`}>
        <ImageContent image={image} oneThird>
          <Content>
            <StoresDetail stores={stores} />
          </Content>
        </ImageContent>
      </Container>
    </BackgroundContainer>
  )
}

StoresWithImage.propTypes = {
  image: PropTypes.shape(GatsbyImageProps),
  stores: StoresProps,
}

export default StoresWithImage
