import React from "react"
import { graphql } from "gatsby"

import { Contact as Page } from "../components/Contact/Contact"

export const query = graphql`
  query {
    page: sanityPageContact {
      title
      phone
      contactHours {
        day
        hours
      }
      email
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      stores {
        name
        sections {
          title
          phone
          address
          city
          state
          postcode
          openHours {
            day
            hours
          }
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
