import React, { useState } from "react"

export const withContact = Component => ({ name = "Contact", page, ...props }) => {
  const [mobileFormActive, setMobileFormActive] = useState(false)
  const {
    title,
    phone,
    contactHours,
    email,
    stores,
    image: {
      asset: { fluid },
    },
  } = page

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: `${title}`,
    },
  ]

  Component.displayName = name
  return (
    <Component
      title={title}
      phone={phone}
      contactHours={contactHours}
      email={email}
      stores={stores}
      image={fluid}
      paths={paths}
      mobileFormActive={mobileFormActive}
      setMobileFormActive={setMobileFormActive}
      {...props}
    />
  )
}
